import { useEffect } from "react"

import "styles/global.scss"
import "styles/fonts.scss"

import "@fortawesome/fontawesome-svg-core/styles.css"
import {
  config as faConfig,
  library as faLibrary
} from "@fortawesome/fontawesome-svg-core"
import {
  faFacebook,
  faFacebookMessenger,
  faGithub,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
  faYoutube
} from "@fortawesome/free-brands-svg-icons"
import {
  faArrowLeftLong,
  faArrowRightLong,
  faBroom,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faCode,
  faCommentCode,
  faEnvelope,
  faExternalLink,
  faHashtag,
  faInfoCircle,
  faLocationArrow,
  faMagnifyingGlass,
  faNetworkWired,
  faPaintbrushPencil,
  faPhone,
  faQuoteLeft,
  faRocketLaunch,
  faSearch,
  faSpinnerThird,
  faStar,
  faTimes,
  faUserCheck
} from "@fortawesome/pro-light-svg-icons"
import { SessionProvider } from "next-auth/react"
import NProgress from "nprogress"
import { SWRConfig } from "swr"

import type { AppProps } from "next/app"

const frontendBrandIcons = [
  faFacebook,
  faGithub,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faGoogle,
  faYoutube,
  faLinkedinIn,
  faFacebookMessenger
]
const frontendLightIcons = [
  faArrowLeftLong, // LogosSlider, ProjectFooter
  faArrowRightLong, // ...
  faSpinnerThird, // form template, search form
  faSearch, // search form
  faEnvelope, // contact-details, share
  faLocationArrow, // contact-details
  faPhone, // contact-details
  faTimes, // modal
  faChevronDown, // dropdown, accordion
  faChevronUp, // dropdown, accordion
  faExternalLink, // href
  faQuoteLeft, // testimonials
  faCheckCircle, // alert
  faCircleExclamation, // alert
  faInfoCircle, // alert
  faNetworkWired, // services - Web design
  faPaintbrushPencil, // services - Web design
  faCode, // services - Web design
  faCommentCode, // services - Web design
  faRocketLaunch, // services - Web design
  faBroom, // services - Web design
  faHashtag, // services - Web design
  faMagnifyingGlass, // react page - seo friendly
  faStar, // reviews
  faUserCheck // social page
]
faConfig.autoAddCss = false
faLibrary.add(...frontendBrandIcons, ...frontendLightIcons)

export default function App({
  Component,
  pageProps,
  router
}: AppProps): React.ReactElement {
  useEffect(() => {
    const handleStart = () => NProgress.start()
    const handleStop = () => {
      NProgress.done()
      // TODO - Check this - https://github.com/vercel/next.js/issues/42492
      // Not scrolling to top on some pages without the following
      document.documentElement.style.scrollBehavior = "auto"
      window.scrollTo(0, 0)
    }

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
    }
  }, [router])

  return (
    <SWRConfig
      value={{
        fetcher: (resource: RequestInfo, init: RequestInit): Promise<JSON> =>
          fetch(resource, init).then(res => res.json()),
        revalidateOnFocus: false
      }}
    >
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
      </SessionProvider>
    </SWRConfig>
  )
}
